.page-alert-container {
  div.alert.javascript {
    display: none;
    text-align: center;
  }
}

.alert {
  border: 1px solid transparent;
  padding: 15px;
  text-align: center;

  &.not-show {
    border: 0 solid transparent;
    border-radius: 0;
    height: 0;
    margin-bottom: 0;
    padding: 0;
    transition: 1s ease;
  }

  &.show {
    border: 1px solid transparent;
    height: 100%;
    padding: 15px;
    transition: 1s ease;
  }

  & h4 {
    color: inherit;
    margin-top: 0;
  }

  & a,
  & &-link {
    color: currentcolor;
    text-decoration: underline;

    &:hover {
      color: var(--bn-orange);
    }
  }

  & > p,
  & > ul {
    margin-bottom: 0;
  }

  & > p + p {
    margin-top: 5px;
  }

  &-success {
    background-color: var(--bn-success-bg);
    border-color: #d6e9c6;
    color: var(--bn-success-color);
  }

  &-success hr {
    border-top-color: #c9e2b3;
  }

  &-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  }

  &-info hr {
    border-top-color: #a6e1ec;
  }

  &-danger,
  &-error {
    background-color: var(--bn-danger-bg);
    border-color: #ebccd1;
    color: var(--bn-danger-color);

    hr {
      border-top-color: #e4b9c0;
    }
  }

  &-warning,
  &.warning {
    background-color: var(--bn-warning-bg);
    border-color: #faebcc;
    color: var(--black);

    hr {
      border-top-color: #f7e1b5;
    }
  }

  &-block.messages {
    word-wrap: break-word;
  }

  &-danger.header,
  &-success.header {
    margin: 0;
  }

  &-success.header {
    text-align: center;

    strong {
      color: #2b542c;
    }
  }
}
